<template>
  <header
    :class="$style.root"
    :data-sticky="isSticky ? '' : null"
    ref="rootElement"
  >
    <div :class="$style.content">
      <button
        @click="$emit('onClickToOpenSearch')"
        :class="[$style.item, $style.buttonSearch]"
      >
        <SvgoSearch :class="$style.svg" />
      </button>

      <LayoutPillComponent
        @click="$emit('onClickToOpenMenu')"
        :class="$style.item"
      >
        <span :class="$style.menuItemLabel">{{ $t('navigation.siteHeader.menu') }}</span>
        <SvgoHamburger :class="$style.svg" />
      </LayoutPillComponent>

      <NavigationLanguageSwitcherComponent
        :class="$style.item"
        :isSticky="isSticky"
      />

      <!-- <NavigationTicketComponent :class="[$style.linkToNewsletter]" /> -->
    </div>
  </header>
</template>

<script setup>
const rootElement = ref(null)

const props = defineProps({
  menuOpen: {
    type: Boolean,
    default: false,
  },
  searchOpen: {
    type: Boolean,
    default: false,
  },
});

// 1
const { y } = useWindowScroll()
const lastY = ref(0)
const dir = ref(null)
watchDebounced(y, (newValue, oldValue) => {
  lastY.value = newValue
  dir.value = newValue > oldValue ? 'down' : 'up'
}, { debounce: 100, maxWait: 500 })

// 2
const { height } = useElementSize(rootElement)
watch(height, (newValue) => {
  if (process.client) {
    document.documentElement.style.setProperty('--site-header--height', `${height.value}px`)
  }
})

const isSticky = computed(() => {
  return dir.value === 'up' || y.value === 0
})
</script>

<style module lang=scss>
.root {
  position: var(--site-header--position, sticky);
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--z-index--site-header);

  transition: transform var(--transition--default);
  transform: translateY(-100%);

  pointer-events: none;
}

.root[data-sticky] {
  transform: translateY(0%);
}

.content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--unit--spacer);

  --padding--button--vertical: calc(var(--padding--button--top) + var(--padding--button--bottom));
  --componsation-top: calc( (var(--padding--button--vertical) / 2) - var(--font-x-small--font-size) / 2);
  --componsation-bottom: calc( (var(--padding--button--vertical) / 2) - var(--font-x-small--font-size) / 2);
  padding-top: calc(var(--unit--vertical) - var(--componsation-top));
  padding-left: var(--unit--horizontal);
  padding-bottom: calc(var(--unit--vertical) - var(--componsation-bottom));
  padding-right: var(--unit--horizontal);

  color: var(--site-header--color, var(--color--black));
}

.item {
  pointer-events: all;
}

.svg {
  width: 1.35ch;
  height: 1ch;
  stroke: var(--color--black);
  stroke-width: .1em;
  overflow: visible;
  transform: translateY(-.1ch);
}

.linkToNewsletter {
  composes: item;
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: $breakpoint-s) {
    display: none !important;
  }
}

.menuItemLabel {
  @media (max-width: $breakpoint-s) {
    display: none;
  }
}

.buttonSearch {
  composes: reset-button button-round from global;
  --button--background-color: var(--color--white);
  --button--background-color--hover: var(--color--gray);
  --width--button--round: calc(var(--padding--button--vertical) + var(--font-x-small--font-size));
  border: var(--stroke--default) solid var(--color--gray);
}

.buttonSearch .svg {
  stroke-width: calc(var(--stroke--default) * 1.5);
  fill: none;
  stroke: var(--color--black);

  transform: translateY(.01ch);
}
</style>
